// container for service grid list handling
import { ServiceList } from '../containers/ServiceList'

// icons
import { Timer, CopyFile, CheckmarkFilled, CloseFilled, NextOutline, Purchase, Edit, Add, EventIncident } from '@carbon/icons-react';

// api imports
import { getOrdersApi, setOrdersApi } from '../utils/api/orders'
import { getManagersApi } from '../utils/api/accounts'
import { getParentAndOwnEntitiesApi } from '../utils/api/entities'
import { getProductsCategoryCompraSERVApi } from '../utils/api/products'
import { getServicesCategoryApi } from '../utils/api/services'

// DataManager form setup
const form = [
    [
        {
            label: 'Código do Pedido',
            helper: '',
            type: 'text',
            key: 'number',
            disabled: true
        },
        {
            label: 'Categoria',
            helper: '',
            type: 'select',
            data: [
                {
                    name: 'Serviços',
                    id: 0
                },
                {
                    name: 'Bens',
                    id: 1
                }
            ],
            //default: 0,
            optionText: 'name',
            key: 'order_category'
        },
        {
            label: 'Tipo',
            helper: '',
            type: 'select',
            fetchApi: getServicesCategoryApi,
            optionText: 'name',
            key: 'service_type',
            context: { order_category: "0" }
        },
        {
            label: 'Tipo',
            helper: '',
            type: 'select',
            fetchApi: getProductsCategoryCompraSERVApi,
            optionText: 'name',
            key: 'product_type',
            context: { order_category: "1" }
        },
        {
            label: 'Tipo',
            helper: '',
            type: 'select',
            key: 'product_type_code',
            context: { order_category: ["0", "1"] },
            contextInvert: true
        }
    ],
    [
        {
            label: 'Solicitante',
            helper: '',
            type: 'text',
            key: 'requester_name',
            disabled: true
        },
        {
            label: 'Prazo para envio de cotações',
            helper: '',
            type: 'date',
            key: 'quote_deadline'
        },
        {
            label: 'Prazo de entrega',
            helper: '',
            type: 'date',
            key: 'shipping_deadline'
        }
    ],
    [
        {
            label: 'Critério de Seleção',
            helper: '',
            type: 'select',
            data: [
                {
                    name: 'Melhor Preço',
                    id: 0
                },
                {
                    name: 'Melhor Prazo',
                    id: 1
                }
            ],
            default: 0,
            optionText: 'name',
            key: 'order_type',
            disabled: true
        },
        {
            label: 'Urgência',
            helper: '',
            type: 'select',
            data: [
                {
                    name: 'Não',
                    id: false
                },
                {
                    name: 'Sim',
                    id: true
                }
            ],
            default: false,
            optionText: 'name',
            key: 'urgent'
        },
        {
            label: 'Número Mínimo de Cotações',
            helper: '',
            type: 'text',
            default: 3,
            key: 'quote_num'
        }
    ],
    [
        {
            label: 'Status',
            helper: '',
            type: 'text',
            key: 'status_code',
            disabled: true
        },
        {
            label: 'Responsável',
            helper: '',
            type: 'select',
            fetchApi: getManagersApi,
            optionText: 'name',
            key: 'responsible'
        }, 
        {
            label: 'Cadastrado por',
            helper: '',
            type: 'text',
            key: 'creator',
            disabled: true
        }
    ],
    [

        {
            label: 'Data de Cadastro',
            helper: '',
            type: 'text',
            key: 'created_at',
            disabled: true
        },
        {
            label: 'Última alteração em',
            helper: '',
            type: 'text',
            key: 'last_updated',
            disabled: true
        },
        {}
    ],
    [
        {
            label: 'Notas',
            helper: '',
            type: 'header',
            key: 'total',
            disabled: true
        },
    ],
    [
        {
            label: 'Justificativa',
            helper: 'Limite de 500 caracteres',
            type: 'longText',
            key: 'justification'
        }
    ],
    [
        {
            label: 'Observações',
            helper: 'Limite de 500 caracteres',
            type: 'longText',
            key: 'observation'
        }
    ],
    [
        {
            label: 'Motivo do Cancelamento',
            helper: 'Limite de 500 caracteres',
            type: 'longText',
            context: { "status": 6 }, // only shows up when status === 6
            key: 'cancel_desc',
            disabled: true
        }
    ],
    [
        {
            label: 'Processamento',
            helper: '',
            type: 'header',
            key: 'total',
            disabled: true
        },
    ],
    [
        {
            label: 'Envio do Pedido para Autorização em',
            helper: '',
            type: 'text',
            key: 'status_datetime',
            idx: 1,
            disabled: true
        },
        {
            label: 'Autorização para Cotação em',
            helper: '',
            type: 'text',
            key: 'status_datetime',
            idx: 2,
            disabled: true
        },
        {
            label: 'Ordem de Compra gerada em',
            helper: '',
            type: 'text',
            key: 'status_datetime',
            idx: 3,
            disabled: true
        },
    ],
    [
        {
            label: 'Envio do Pedido em',
            helper: '',
            type: 'text',
            key: 'status_datetime',
            idx: 4,
            disabled: true
        },
        {
            label: 'Recebimento do Pedido em',
            helper: '',
            type: 'text',
            key: 'status_datetime',
            idx: 5,
            disabled: true
        },
        {}
        /* {
            label: 'Recebido por',
            helper: '',
            type: 'text',
            key: 'status',
            disabled: true
        }, */
    ],
    [
        {
            label: 'Anexos',
            helper: '',
            type: 'multimedia',
            key: 'attachments',
            medias: [
                {
                    label: 'Documentos',
                    helper: 'Tamanho máximo de arquivo é 2Mb. Formatos suportados: Doc, PDF.',
                    key: 'doc'
                },
                {
                    label: 'Fotos e Imagens',
                    helper: 'Tamanho máximo de arquivo é 2Mb. Formatos suportados: JPG e PNG.',
                    key: 'image'
                },
                {
                    label: 'Videos',
                    helper: 'Suporte a links disponibilizados publicamente. Serviços suportados: Youtube, Vimeo, Dailymotion',
                    key: 'url'                    
                }
            ]
        }
    ],
    [
        {
            label: 'Pedido 2024/0018: Todos os Itens',
            type: 'component',
            component: ServiceList
        }
    ],


    [
        {
            label: 'Dados de Envio / Remessa',
            helper: '',
            type: 'header',
            context: { status: [0,1,2,3,4,5,6,7] },
            disabled: true
        },
    ],
    [
        {
            label: 'CNPJ',
            helper: '',
            type: 'text',
            context: { status: [0,1,2,3,4,5,6,7] },
            key: 'entity_cnpj',
            disabled: true
        },
        {
            label: 'Nome',
            helper: '',
            type: 'text',
            context: { status: [0,1,2,3,4,5,6,7] },
            key: 'entity_name',
            disabled: true
        },
        {
            label: 'CEP',
            helper: '',
            type: 'text',
            context: { status: [0,1,2,3,4,5,6,7] },
            key: 'entity_cep',
            disabled: true
        }
    ],
    [
        {
            label: 'Endereço',
            helper: '',
            type: 'text',
            context: { status: [0,1,2,3,4,5,6,7] },
            key: 'entity_endereco',
            disabled: true
        },
        {
            label: 'Número',
            helper: '',
            type: 'text',
            context: { status: [0,1,2,3,4,5,6,7] },
            key: 'entity_numero',
            disabled: true
        },
        {
            label: 'Complemento',
            helper: '',
            type: 'text',
            context: { status: [0,1,2,3,4,5,6,7] },
            key: 'entity_complemento',
            disabled: true
        }
    ],
    [
        {
            label: 'UF',
            helper: '',
            type: 'text',
            context: { status: [0,1,2,3,4,5,6,7] },
            key: 'entity_uf_code',
            disabled: true
        },
        {
            label: 'Cidade',
            helper: '',
            type: 'text',
            context: { status: [0,1,2,3,4,5,6,7] },
            key: 'entity_cidade_code',
            disabled: true
        },
        {
            label: 'Gestor',
            helper: '',
            type: 'text',
            context: { status: [0,1,2,3,4,5,6,7] },
            key: 'entity_manager_name',
            disabled: true
        }
    ],
    /* [
        {
            label: 'Telefone',
            helper: '',
            type: 'text',
            context: { status: [0,1,2,3,4,5,6,7] },
            key: 'entity_phone',
            disabled: true
        },
        {
            label: 'E-mail',
            helper: '',
            type: 'text',
            context: { status: [0,1,2,3,4,5,6,7] },
            key: 'entity_email',
            disabled: true
        },
        {}
    ], */
    [
        {
            label: 'Dados da Nota Fiscal',
            helper: '',
            type: 'header',
            context: { status: [0,1,2,3,4,5,6,7] },
            disabled: true
        },
    ],
    [
        {
            label: 'CNPJ',
            helper: '',
            type: 'text',
            context: { status: [0,1,2,3,4,5,6,7] },
            key: 'parent_cnpj',
            disabled: true
        },
        {
            label: 'Nome',
            helper: '',
            type: 'text',
            context: { status: [0,1,2,3,4,5,6,7] },
            key: 'parent_name',
            disabled: true
        },
        {
            label: 'CEP',
            helper: '',
            type: 'text',
            context: { status: [0,1,2,3,4,5,6,7] },
            key: 'parent_cep',
            disabled: true
        }
    ],
    [
        {
            label: 'Endereço',
            helper: '',
            type: 'text',
            context: { status: [0,1,2,3,4,5,6,7] },
            key: 'parent_endereco',
            disabled: true
        },
        {
            label: 'Número',
            helper: '',
            type: 'text',
            context: { status: [0,1,2,3,4,5,6,7] },
            key: 'parent_numero',
            disabled: true
        },
        {
            label: 'Complemento',
            helper: '',
            type: 'text',
            context: { status: [0,1,2,3,4,5,6,7] },
            key: 'parent_complemento',
            disabled: true
        }
    ],
    [
        {
            label: 'UF',
            helper: '',
            type: 'text',
            context: { status: [0,1,2,3,4,5,6,7] },
            key: 'parent_uf_code',
            disabled: true
        },
        {
            label: 'Cidade',
            helper: '',
            type: 'text',
            context: { status: [0,1,2,3,4,5,6,7] },
            key: 'parent_cidade_code',
            disabled: true
        },
        {
            label: 'Gestor',
            helper: '',
            type: 'text',
            context: { status: [0,1,2,3,4,5,6,7] },
            key: 'parent_manager_name',
            disabled: true
        }
    ],
    /* [
        {
            label: 'Telefone',
            helper: '',
            type: 'text',
            context: { status: [0,1,2,3,4,5,6,7] },
            key: 'parent_phone',
            disabled: true
        },
        {
            label: 'E-mail',
            helper: '',
            type: 'text',
            context: { status: [0,1,2,3,4,5,6,7] },
            key: 'parent_email',
            disabled: true
        },
        {}
    ], */

    
    [
        {
            label: 'Status for Actions',
            helper: '',
            type: 'hidden',
            key: 'status',
            disabled: true
        },
        {
            label: 'Order Items',
            helper: '',
            type: 'hidden',
            key: 'order_items'
        },
        {
            label: 'New Order Items',
            helper: '',
            type: 'hidden',
            key: 'new_order_items'
        },
        {
            label: 'Requester ID',
            helper: '',
            key: 'requester',
            disabled: true
        },
        {
            label: 'Entity Fetch',
            key: '_',
            fetchApi: getParentAndOwnEntitiesApi,
            fetchApiParam: 'requester',
            disabled: true
        },
    ]
]

// DataGrid headers and data table formating setup
const dataGridHeader = [
    {
        header: 'Código',
        key: 'number'
    },
    {
        header: 'Solicitante',
        key: 'requester_name'
    },
    {
        header: 'Tipo',
        key: 'order_type_code'
    },
    {
        header: 'Justificativa',
        key: 'justification'
    },
    {
        header: 'Lançamento',
        key: 'created_at'
    },
    {
        header: 'Itens',
        key: 'order_products'
    },
    {
        header: 'Cotações',
        key: 'order_quotes'
    },
    {
        header: 'Status',
        key: 'status_code'
    }
]

const dataGridSearchBy = dataGridHeader.map(item => item.key.endsWith('_code') ? item.key.slice(0, -5) : item.key)

const dataGridFilterBy = [
    { 
        label: 'Cadastrados',
        query: [
            {
                key: 'status',
                value: 0
            }
        ]
    },
    { 
        label: 'Autorizados',
        query: [
            {
                key: 'status',
                value: 1
            }
        ]
    },
    { 
        label: 'Cotados',
        query: [
            {
                key: 'status',
                value: 2
            }
        ]
    },
    { 
        label: 'Ordens de Compra',
        query: [
            {
                key: 'status',
                value: 3
            }
        ]
    },
    { 
        label: 'Enviados',
        query: [
            {
                key: 'status',
                value: 4
            }
        ]
    },
    { 
        label: 'Recebidos',
        query: [
            {
                key: 'status',
                value: 5
            }
        ]
    },
    { 
        label: 'Cancelados',
        query: [
            {
                key: 'status',
                value: 6
            }
        ]
    },
    { 
        label: 'Desertos',
        query: [
            {
                key: 'status',
                value: 7
            }
        ]
    }
]

// grab those actions on onAction
const formActions = {
    // override default add, edit and delete actions: those are not called back
    // they are default handled inside DataCRUD
    "add": {
        "label": "Adicionar",
        "permission": "add_order",
        "icon": Add
    },
    "edit": {
        "context": { "status": 0 }, 
        "label": "Editar",
        "permission": "change_order",
        "icon": Edit
    },
    "requestedOperator": {
        "context": { "status": 0 }, 
        "label": "Aguardando Autorização",
        "icon": Timer,
        "disabled": true
    },
    "authorize": {
        "context": { "status": 0 }, 
        "label": "Autorizar",
        "permission": "authorize_order",
        "icon": NextOutline
    },
    "authorized": {
        "context": { "status": 1 },
        "label": "Aguardando Cotações",
        "icon": Timer,
        "disabled": true
    },
    "order": {
        "context": { "status": 2 },
        "label": "Revisar / Criar Ordem de Compra",
        "permission": "buy_order",
        "icon": NextOutline
    },
    "reviewQuotes": {
        "context": { "status": [3,4,5,6,7] },
        "label": "Revisar Cotações",
        "permission": "buy_order",
        "icon": NextOutline
    },
    "ordered": {
        "context": { "status": 3 },
        "label": "Aguardando Envio",
        "icon": Timer,
        "disabled": true
    },
    /* "shipped": {
        "context": { "status": 4 },
        "label": "Marcar Pedido como Recebido",
        "permission": "receive_order",
        "icon": CheckmarkFilled
    }, */
    /* "received": {
        "context": { "status": 5 },
        "label": "Recebido",
        "icon": CheckmarkFilled,
        "disabled": true
    }, */
    "canceled": {
        "context": { "status": 6 },
        "label": "Cancelado",
        "icon": CloseFilled,
        "disabled": true
    },
    "cancel": {
        "context": { "status": [0,1,2] }, 
        "label": "Cancelar",
        "permission": "cancel_order",
        "icon": CloseFilled
    },
    "clone": {
        "label": "Clonar",
        "permission": "add_order",
        "icon": CopyFile
    }
}

const STATUS_CHOICES = [
    'Cadastrados',
    'Autorizados',
    'Cotados',
    'Ordens de Compra',
    'Enviados',
    'Recebidos',
    'Cancelados',
    'Desertos'
]

// re-process data from backend to distribute multi-order orders into their own tabs
function handleOrderData(data, filter, exportMode) {
    if (!data || !Array.isArray(data) || data.length === 0) {
        return []
    }

    // no post processing in case export mode on
    if (exportMode)
        return data
    
    data.forEach(order => {
        if (order.status >= 1) {
            order.status = filter?.query?.[0]?.value
            order.status_code = filter.label
        }
    })
    
    return data
/* 
    const newData = []

    data.forEach(order => {
        if (order.status < 3 || order.status >= 5)
            return

        const modifiedOrder = {}
        const quotationIdsToRemove = []

        order.quotations.forEach(quote => {
            if (quote.status !== order.status && parseFloat(quote.winner_total.replaceAll(".","").replaceAll(",",".")) > 0) {
                modifiedOrder.status = quote.status
                modifiedOrder.status_code = STATUS_CHOICES[quote.status]

                // Remove quotes with different status
                if (quote.status !== modifiedOrder.status) {
                    quotationIdsToRemove.push(quote.id)
                }
            }
        })
        
        if (Object.keys(modifiedOrder).length > 0) {
            // Remove quotations with different status from the modified order
            modifiedOrder.quotations = order.quotations.filter(
                quote => !quotationIdsToRemove.includes(quote.id)
            );
    
            newData.push({ ...order, ...modifiedOrder })
        }

    })
    
    return [...data, ...newData] */
}

// the form extension for product list and order list
// it receives managerData with all current form data

// Datagrid for order items
export const orderItemsGridHeader = [
    {
        header: 'Nome',
        key: 'name'
    },
    {
        header: 'Tipo',
        key: 'product_type_code'
    },
    {
        header: 'Solicitante',
        key: 'requester_name'
    },
    {
        header: 'Qtd Solicitada',
        key: 'quantity'
    },
    {
        header: 'Apresentação',
        key: 'presentation'
    },
    {
        header: 'Valor Unitário',
        key: 'price'
    },
    {
        header: 'Valor Total',
        key: 'total'
    }
]

//
// QuotesList share
//
// Datagrid for quote items that becomes product list orders(multi or single winner)
export const quoteItemsGridHeader = [
    {
        header: 'Nome do Produto',
        key: 'item_product_name'
    },
    {
        header: 'Tipo',
        key: 'item_product_product_type_code'
    },
    {
        header: 'Solicitante',
        key: 'item_requester_name'
    },
    {
        header: 'Qtd Solicitada',
        key: 'item_quantity'
    },
    {
        header: 'Apresentação',
        key: 'item_product_presentation'
    },
    {
        header: 'Valor Unitário',
        key: 'price'
    },
    {
        header: 'Valor Total',
        key: 'total'
    }
]

export const quoteItemsSearchBy = ['item_product_name', 'item_product_product_type_code', 'item_requester_name', 'item_quantity', 'item_product_presentation', 'price', 'total']

export const gridQuotesActions = {
    "confirmOrder": {
        "context": { "winner": true },
        "label": "Gerar Ordem de Compra",
        "icon": Purchase
    },
    /* "cantBuy": {
        "context": { "winner": false },
        "label": "Gerar Ordem de Compra",
        "icon": Purchase,
        "disabled": true
    }, */
    "noMinimunOrder": {
        "context": { "status": 7 },
        "label": "Restrito",
        "icon": EventIncident
    }
}

export const gridOrderQuoteActions = {
    "waitingShipping": {
        "context": { "status": 3 },
        "label": "Aguardando Envio",
        "icon": Timer,
        "disabled": true
    },
    "shipped": {
        "context": { "status": 4 },
        "label": "Marcar Pedido como Recebido",
        "permission": "receive_order",
        "icon": CheckmarkFilled
    },
    "received": {
        "context": { "status": 5 },
        "label": "Pedido Recebido",
        "icon": CheckmarkFilled,
        "disabled": true
    }
}

// the main DataCRUD setup
export const ordersDataCRUD = {
    headers: dataGridHeader, 
    searchBy: dataGridSearchBy,
    filters: dataGridFilterBy,
    form: form,
    formTitleKey: 'number',
    formActions: formActions,
    getApi: getOrdersApi,
    getApiProcessData: handleOrderData,
    setApi: setOrdersApi
}
