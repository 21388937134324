import { useState, useEffect } from "react"
import { 
    Stack, 
    Heading, 
    Section, 
    Tile,
    FlexGrid,
    Row,
    Column,
    Modal,
    TextArea
} from '@carbon/react'
import { useAuth } from '../contexts/AuthContext'

// containers
import { UiShell } from '../containers/Template'
import { DataCRUD, OpMode } from '../containers/DataCRUD'

// components
import EntityNavigation from '../components/EntityNavigation'
import { QuotesList } from '../containers/QuotesList'

// api imports
import { getBalanceStats } from '../utils/api/balance'
import { getOrdersStats, cancelOrderApi, setBuyOrderApi, authorizeOrderApi, receiveOrderApi, cloneOrderApi } from '../utils/api/orders'

// datacrud setups
import { ordersDataCRUD } from '../datacruds/compramed-orders'

// move to a strings datafile?
const cancelConfirmMsg = "O pedido selecionado será cancelado e removido do cadastro. Os dados nele contidos serão mantidos no sistema para fins de relatório. Deseja realmente remover?"
const restoreConfirmMsg = "O pedido selecionado será readicionado à lista. Deseja continuar?"

const confirmOrderMsg = "Ao confirmar você realizará a confirmação do pedido e gerará uma Ordem de Compra, autorizando o fornecedor a realizar a remessa. Esta ação não pode ser desfeita ou cancelada."

const CompraMedOrders = () => {
    const [ selectedEntity, setSelectedEntity ] = useState(null)
    const [ selectedOrder, setSelectedOrder ] = useState(null)
    const [ entitiesNavPath, setEntitiesNavPath ] = useState('')
    const [ stats, setStats ] = useState({})
    const [ balanceStats, setBalanceStats ] = useState({})
    const [ cancelOrderModal, setCancelOrderModal ] = useState(0)
    const [ confirmOrderModal, setConfirmOrderModal ] = useState(0)
    const [ confirmOrderNoBalanceModal, setConfirmOrderNoBalanceModal ] = useState(0)
    const [ confirmOrderCloneModal, setConfirmOrderCloneModal ] = useState(0)
    const [ noMinimunOrderModal, setNoMinimunOrderModal ] = useState(0)
    const [ orderDataId, setOrderDataId ] = useState("")
    const [ cancelDesc, setCancelDesc ] = useState("")
    const [ reloadDataCRUD, setReloadDataCRUD ] = useState(0)
    const [ viewQuotes, setViewQuotes ] = useState(false)
    const [ viewQuotesReview, setViewQuotesReview ] = useState(false)
    const { user } = useAuth()
    const canAdd = user.permissions.includes('add_order') && selectedEntity
    const canChange = user.permissions.includes('change_order')
    //const canAddOrderItem = true //editMode === OpMode.create || editMode === OpMode.edit ? true : false

    // DataCRUD callbacks
    // on each form display request we callback with the form data
    const onFormDisplay = async (data, formEditMode) => {
        if ((formEditMode === OpMode.standby || formEditMode === OpMode.cancel) && data === null) {
            setSelectedOrder(null)
        } else if (formEditMode === OpMode.create) {
            setSelectedOrder(null)
        } else if (data?.order_items && (formEditMode === OpMode.edit || formEditMode === OpMode.read)) {
            // from buy orders on
            //if (data.status >= 3) {
            //    setSelectedOrder(data)
            //} else {
                setSelectedOrder(data)
            //}
        }
    }
    
    // Actions callback: based on ordersDataCRUD formActions
    const onDataAction = async (action, data) => {
        switch (action) {
            case "cancel":
                setOrderDataId(data.id)
                setCancelOrderModal(1)
            break
            case "authorize": 
                //"context": { "status": 0 }, 
                //"label": "Encaminhar para Autorização"
                await authorizeOrderApi(data.id)
                setReloadDataCRUD(reloadDataCRUD+1)
            break
            case "order": 
                //"context": { "status": 2 },
                //"label": "Revisar / Criar Ordem de Compra"
                // get quote results page view and data
                setOrderDataId(data.id)
                //getQuotedData(data.id)
                setViewQuotes(true)
            break
            case "confirmOrder": 
                // after click on "Gerar Orderm de Compra"
                // shows modal asking for confirmation
                setConfirmOrderModal(1)
                // resolved on onConfirmOrderAction
            break
            case "shipped": 
                //"context": { "status": 4 },
                //"label": "Marcar Pedido como Recebido",
                receiveOrderApi(data)
                setReloadDataCRUD(reloadDataCRUD+1)
            break
            case "reviewQuotes": 
                //"context": { "status": [3,4,5,6,7] },
                //"label": "Revisar Cotacoes",
                setOrderDataId(data.id)
                //getQuotedData(data.id)
                setViewQuotesReview(true)
                setViewQuotes(true)
            break           
            case "clone":
                setOrderDataId(data.id)
                setConfirmOrderCloneModal(1)
            break
            case "noMinimunOrder":
                // shows a modal with detailed information about what happens to noMinimunOrder status
                setNoMinimunOrderModal(1)
            break
            default:
                // does nothing...
            break
        }
    }

    // called by modals
    const onCancelRestoreAction = async (e) => {
        if (cancelOrderModal === 1) {
            await cancelOrderApi(orderDataId, cancelDesc)
        } else if (cancelOrderModal === 2) {
            //await changeOrderStatus(orderDataId, 0) // bring it to status 0?
        }
        setCancelOrderModal(0)
        // empty desc filed
        setCancelDesc("")
        setReloadDataCRUD(reloadDataCRUD+1)
        // reload dashboard stats
        fetchStatsData()
    }

    // after quotes, when mnager clicks on generate buy order
    const onConfirmOrderAction = async () => {
        //"Revisar / Criar Ordem de Compra",
        const response = await setBuyOrderApi(orderDataId)
        //changeOrderStatus(orderDataId, 3)
        setOrderDataId("")
        setConfirmOrderModal(0)

        if (response.status === 400) {
            // TODO: check if this is a no balance order problem or a generic one!
            if (response.data.error === 'no balance') {
                setConfirmOrderNoBalanceModal(1)
            } else {
                console.log('generic error')
            }
        } else {
            setViewQuotes(false)
            // force reload to get new debt and balance stats data
            setReloadDataCRUD(reloadDataCRUD+1)
        }
    }

    const onConfirmOrderCloneAction = async () => {
        console.log(orderDataId)
        await cloneOrderApi(orderDataId)
        // force reload to get new debt and balance stats data
        setReloadDataCRUD(reloadDataCRUD+1)
        // close modal
        setConfirmOrderCloneModal(0)
    }

    const onSaveData = (result) => {
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            console.log(`Data saved: ${result}`)
            // reload dashboard stats
            fetchStatsData()
        }
    }

    const onCreateData = (result) => {
        // update object data?
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            console.log(`Data created: ${result}`)
            // reload dashboard stats
            fetchStatsData()
        }
    }

    const fetchOrdersStats = async () => {
        const data = await getOrdersStats()
        setStats(data)
    }

    const fetchBalanceStats = async () => {
        const data = await getBalanceStats()
        setBalanceStats(data)
    }

    const fetchStatsData = () => {
        fetchBalanceStats()
        fetchOrdersStats()
    }

    const onNavChange = (entityId, place, name) => {
        setSelectedEntity(entityId)
        setEntitiesNavPath(place)
        //setSelectedEntityName(name) // we should inject this data into product list
    }
    
    useEffect(() => {
        fetchStatsData()
    }, [])
    
    return (
        <UiShell>
            <Stack gap={8}>
                {confirmOrderModal !== 0 &&
                    <Modal 
                        open={confirmOrderModal}
                        modalHeading="Gerar Ordem de Compra"
                        /* modalLabel={} */
                        primaryButtonText="Autorizar Compra e Remessa" 
                        secondaryButtonText="Cancelar" 
                        onRequestSubmit={onConfirmOrderAction} 
                        onRequestClose={() => setConfirmOrderModal(0)}
                    >
                        <p>{confirmOrderMsg}</p>
                    </Modal>
                }
                {confirmOrderNoBalanceModal !== 0 &&
                    <Modal 
                        open={confirmOrderNoBalanceModal}
                        modalHeading="Saldo Insuficiente"
                        primaryButtonText="Fechar" 
                        onRequestSubmit={() => { setViewQuotes(false); setConfirmOrderNoBalanceModal(0)}}
                        onRequestClose={() => { setViewQuotes(false); setConfirmOrderNoBalanceModal(0)}}
                    >
                        <p>{"O saldo é insuficiente para gerar a Ordem de Compra. Para completar essa operação, adicione mais saldo na aba EMPENHO."}</p>
                    </Modal>
                }
                {noMinimunOrderModal !== 0 &&
                    <Modal 
                        open={noMinimunOrderModal}
                        modalHeading="Valor Mínimo de Pedido Não Alcançado"
                        primaryButtonText="Fechar" 
                        onRequestSubmit={() => setNoMinimunOrderModal(0)}
                        onRequestClose={() => setNoMinimunOrderModal(0)}
                    >
                        <p>{"O Valor Mínimo para realização deste pedido junto a este Fornecedor não foi alcançado e uma Ordem de compra não poderá ser gerada."}</p>
                    </Modal>
                }
                {confirmOrderCloneModal !== 0 &&
                    <Modal 
                        open={confirmOrderCloneModal}
                        modalHeading="Clonar Pedido"
                        /* modalLabel={} */
                        primaryButtonText="Clonar" 
                        secondaryButtonText="Cancelar" 
                        onRequestSubmit={onConfirmOrderCloneAction} 
                        onRequestClose={() => setConfirmOrderCloneModal(0)}
                    >
                        <p>Você está prestes a criar uma <b>cópia</b> do pedido selecionado. O novo pedido será enviado para <b>Cadastrados</b>, e seus <b>dados</b> devem ser <b>editados</b>.</p>
                        <p>Deseja mesmo continuar?</p>
                    </Modal>
                }
                {cancelOrderModal !== 0 &&
                    <Modal 
                        open={cancelOrderModal >= 1 ? true : false}
                        danger 
                        modalHeading="Cancelamento de Pedido"
                        primaryButtonText="Sim" 
                        secondaryButtonText="Não" 
                        onRequestSubmit={onCancelRestoreAction} 
                        onRequestClose={() => setCancelOrderModal(0)}
                    >
                        <p>{cancelOrderModal === 1 ? cancelConfirmMsg : restoreConfirmMsg}</p>
                        {cancelOrderModal === 1 &&
                            <TextArea
                                invalidText={''}
                                invalid={false} 
                                onChange={(e) => setCancelDesc(e.target.value)} 
                                disabled={false} 
                                value={cancelDesc} 
                                id={'cancelRestoreInput'} 
                                name={'cancelRestoreInput'} 
                                labelText={'Motivo do Cancelamento'} 
                                helperText={'Limite de 500 caracteres'} 
                            /> 
                        } 
                    </Modal>
                }
                <Section style={{ display: viewQuotes ? 'none' : 'block' }}>
                    <Stack gap={6}>
                        <Section>
                            <Heading className="primary">Solicitações de Compra</Heading>
                            <p>As solicitações de compra acompanham o processo de aquisição de produtos em todas as suas fases.</p>
                        </Section>
                        <Section level={4}>
                            <FlexGrid style={{ paddingInline: '0px' }}>
                                <Row>
                                    <Column>
                                        <Tile>
                                            <Heading>{stats?.requested}</Heading>
                                            Solicitações
                                        </Tile>
                                    </Column>
                                    <Column>
                                        <Tile>
                                            <Heading>R$ {balanceStats?.withdraw}</Heading>
                                            Valor Total de Destinado
                                        </Tile>
                                    </Column>
                                    <Column>
                                        <Tile>
                                            <Heading>R$ {balanceStats?.balance}</Heading>
                                            Saldo
                                        </Tile>
                                    </Column>
                                </Row>
                                <Row style={{ marginTop: '1.5rem' }}>
                                    <Column>
                                        <Tile>
                                            <Heading>{stats?.authorized}</Heading>
                                            Autorizados
                                        </Tile>
                                    </Column>
                                    <Column>
                                        <Tile>
                                            <Heading>{stats?.quoted}</Heading>
                                            Cotados
                                        </Tile>
                                    </Column>
                                    <Column>
                                        <Tile>
                                            <Heading>{stats?.ordered}</Heading>
                                            Ordens de Compra
                                        </Tile>
                                    </Column>
                                </Row>
                                <Row style={{ marginTop: '1.5rem' }}>
                                    <Column>
                                        <Tile>
                                            <Heading>{stats?.shipped}</Heading>
                                            Enviados
                                        </Tile>
                                    </Column>
                                    <Column>
                                        <Tile>
                                            <Heading>{stats?.received}</Heading>
                                            Recebidos
                                        </Tile>
                                    </Column>
                                    <Column>
                                        <Tile>
                                            <Heading>{stats?.canceled}</Heading>
                                            Cancelados
                                        </Tile>
                                    </Column>
                                </Row>
                            </FlexGrid>
                        </Section>
                        <Section>
                            <EntityNavigation selectedEntity={selectedEntity} onChange={onNavChange} />
                        </Section>
                        <Section>
                            <DataCRUD 
                                title={'Pedidos'} 
                                desc={entitiesNavPath} 
                                
                                {...ordersDataCRUD}

                                permissions={user.permissions}

                                getApiParam={{ entityId: selectedEntity }}
                                
                                onFormDisplay={onFormDisplay}
                                /* onFilterChange={onFilterChange} */

                                canAdd={canAdd}
                                canChange={canChange}

                                /* setApiParam={{ requester: selectedEntity, new_order_items: saveItems }} */
                                /* instead of prepare saveItems here we re-inject saveItems on current form via componentInjection for auto save */
                                setApiParam={{ requester: selectedEntity }}

                                onSave={onSaveData}
                                onCreate={onCreateData}
                                onAction={onDataAction}
                                forceReload={reloadDataCRUD}
                                /* we need to reinject ProductList component here and use managerData keys instead of local transoform */
                            /> 
                        </Section>
                    </Stack>
                </Section>
                {viewQuotes &&
                    <Stack gap={6}>
                        <Section>
                                <Heading className="primary">{'Cotações Recebidas'}</Heading>
                        </Section>
                        <Section>
                                <div><a href="#" onClick={() => { setViewQuotes(false); setViewQuotesReview(false) } }>Voltar para Lista</a> / {`Pedido ${selectedOrder?.number}`}</div>
                        </Section>
                        <QuotesList 
                            orderId={orderDataId} 
                            onAction={onDataAction}
                            reviewMode={viewQuotesReview}
                        />
                    </Stack>
                }
            </Stack>
        </UiShell>
    )
}

export default CompraMedOrders