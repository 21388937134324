import { useState, useEffect } from "react";
import { 
    Stack, 
    Heading, 
    Section, 
    Modal,
    TextInput,
    ComboBox,
} from '@carbon/react';
import { DataCRUD, OpMode } from './DataCRUD'
import { useAuth } from '../contexts/AuthContext';

// utils
import { maskValue } from '../utils/inputMasks'

// api imports
import { getProductsApi } from '../utils/api/products'
import { getQuotesApi } from '../utils/api/quotes'

// DataCRUD definitions for quoted product list(multi or single winner)
import { quoteItemsGridHeader, gridOrderQuoteActions, orderItemsGridHeader } from  '../datacruds/compraserv-orders'

const SEARCH_BY = ['name', 'product_type_code', 'quantity', 'presentation', 'price', 'total']

export const ServiceList = ({ managerData, setManagerData, editMode, filter, onAction }) => {
    const [ productOrderModal, setProductOrderModal ] = useState(0)
    const [ productQuantity, setProductQuantity ] = useState(0)
    const [ productList, setProductList ] = useState(null)
    const [ productCategories, setProductCategories ] = useState(null)
    const [ selectedProduct, setSelectedProduct ] = useState(null)
    const [ searchTimer, setSearchTimer ] = useState(undefined)
    const [ canAddOrderItem, setCanAddOrderItem ] = useState(false)
    const { user } = useAuth()
    const canChangeProductList = user.permissions.includes('change_order')
    const [ quotesData, setQuotesData ] = useState([])
    const [ orderItems, setOrderItems ] = useState(null)
    const [ isProductInvalid, setIsProductInvalid ] = useState(false)
    const [ productInvalidText, setProductInvalidText ] = useState('')
    const [ isProductInvalidQuantity, setIsProductInvalidQuantity ] = useState(false)

    const resetProductListForm = () => {
        // reset select product form
        setSelectedProduct(null)
        setProductQuantity(0)
        setProductList(null)
        setIsProductInvalid(false)
        setIsProductInvalidQuantity(false)
    }

    // called from order item adicionar button
    const onOrderItemAddRequest = () => {
        setProductOrderModal(1)
    }

    // add order product modal add callback
    const onAddOrderProductAction = () => {
        if (selectedProduct === null) {
            setIsProductInvalid(true)
            setIsProductInvalidQuantity(false)
            setProductInvalidText("Selecione um produto.")
            return
        } else if (productQuantity <= 0) {
            setIsProductInvalidQuantity(true)
            setIsProductInvalid(false)
            return
        } 

        const newOrderItem = { ...selectedProduct, quantity: productQuantity, requester_name: managerData?.requester_name, total: 0, price: 0 }
        if (orderItems.find(e => e.id === newOrderItem.id)) {
            setIsProductInvalid(true)
            setProductInvalidText("O produto selecionado ja existe em seu pedido")
            // or we can update the quantity on the order...
            // check if order item exist on the product list and change adicionar to modificar, load the current total on field....
        } else {
            // format and update grid with new order item
            formatOrderItems([ newOrderItem, ...orderItems])
            // close modal
            setProductOrderModal(0)
            resetProductListForm()
        }
    }

    /* const onFetchProductListByType = async (typeId) => {
        // gets the product by type
        // use the normal query?
        const productsByType = await getProductsByTypeApi(typeId)
        if (productsByType?.results.length > 0)
            setProductList(productsByType.results)
    } */

    const onDeleteProductItem = (items) => {
        const newOrderItems = orderItems.filter(e => !items.some(item => item.id === e.id))
        formatOrderItems(newOrderItems)
    }
    
    const onSetSelectedProduct = (product) => {
        setSelectedProduct(productList?.find(e => e.id === product?.id))
    }

    const onSearchProduct = (input) => {
        if (input === '') {
            // reset form
            resetProductListForm()
            return
        }
        // If there is any timer already set, reset the counter timeout
        if (searchTimer) 
            clearTimeout(searchTimer);

        const data = {
            params: {
                page: 1,
                page_size: 100,
                //ordering: ordering,
                //filter: filterData,
                search: `name:${input},presentation:${input},description:${input}`,
            }
        }
        // No timer setup? Start a 300ms timer and call onSearchProduct when the timer ends
        setSearchTimer(setTimeout(async () => {
            const productsByType = await getProductsApi(data)
            if (productsByType?.results)
                setProductList(productsByType.results)
        }, 300))
    }
    
    const formatOrderItems = (gridItems) => {
        // set grid data
        setOrderItems(gridItems)
        // set save buffer
        const newOrderItems = gridItems?.map(item => ({ product: item.id, quantity: item.quantity }))
        //setSaveItems(newOrderItems)
        // here we should return to manager data our saved items
        setManagerData({ ...managerData, new_order_items: newOrderItems });
    }

    useEffect(() => {
        if ((editMode === OpMode.create) || (managerData?.status === 0 && editMode === OpMode.edit)) {
            setCanAddOrderItem(true)
        }
    }, [editMode])

    useEffect(() => {
        if (editMode === OpMode.create) {
            formatOrderItems([])
        } else if (managerData?.status >= 3 && managerData?.status <= 5) {
            getOrderQuotesData(managerData.id)
        } else {
            //const gridItems = managerData['order_items'].map(item => ({ ...item.product, quantity: item.quantity, requester_name: managerData?.requester_name, total: item.product.price*item.quantity }))
            const gridItems = managerData?.['order_items']?.map(item => ({ ...item.product, quantity: item.quantity, requester_name: managerData?.requester_name, price: 0, total: 0*item.quantity }))
            formatOrderItems(gridItems)
        }
    }, [managerData.id])

    useEffect(() => {
        // avoid retrieving categories when read mode
        if (editMode === OpMode.read)
            return
    }, [editMode])
    
    // we can use a callback if needs to update any manager data

    const getOrderQuotesData = async (dataId) => {
        const response = await getQuotesApi({ orderId: dataId })
        
        if (response.length === 0)
            return

        // 1 transform any non quoted item into Indisponivel or RDS
        const quotations = response.map(obj => {
            let quote_items
            if (obj.status === 8) {
                quote_items = obj.quote_items.map(item => ({
                    ...item,
                    price: "RDS",
                    total: "RDS"
                }));
            } else {
                quote_items = obj.quote_items.map(item => ({
                    ...item,
                    price: item.price === "0,00" ? "Indisponível" : item.price,
                    total: item.total === "0,00" ? "Indisponível" : item.total
                }));
            }
            return {
                ...obj,
                quote_items: quote_items
            }
        })

        // first we check if this is a mix of winners of a single winner:
        const isSingleWinner = quotations?.reduce((acc, e) => acc || e.winner, false);

        if (isSingleWinner) {
            // dont create a mix of best quotes for view...
            setQuotesData(quotations)
        } else {
            // process winner_quote_items and winner_total
            // process loser_quote_items and loser_total
    
            // the generated buy order will triger all quotes winners in case more than one supplier wins
            // each quotation will update his status from 2 to 3... and follow as it was a order status flow
            // a endpoint will receive the order ID only, updating his status to ordered
            let totalWinner = 0;  // Initialize total winner variable
            let quoteItems = [];  // Initialize quote_items array
            let orderItems = []
    
            quotations.forEach(quotation => {
                if (quotation.status === 8) {
                    orderItems = quotation.quote_items?.map(item => ({
                        ...item,
                        quote: 'RDS'
                    }))
                } else {
                    totalWinner += parseFloat(quotation.winner_total.replaceAll(".","").replaceAll(",",".")) //parseFloat(quotation.winner_total)
                    // Concatenate winner_quote_items to quote_items
                    quoteItems = quoteItems.concat(
                        quotation.winner_quote_items.map(item => ({
                          ...item,
                          quote_id: quotation.id
                        }))
                      )
    
                    // for unvaliable items...
                    if (orderItems.length === 0) {
                        orderItems = quotation.quote_items?.map(item => ({
                            ...item,
                            quote: 'Indisponível'
                        }))
                    }
                } 
            })
            
            // if there are any desert item we should add to the winnerQuote
            const unvaliableItems = orderItems?.filter(item => !quoteItems.some(existingItem => existingItem.item.product.id === item.item.product.id))
            quoteItems.push(...unvaliableItems)
            
            // the main multi order
            const winnerQuote = {
                winner_quote_items: quoteItems,
                winner_total: totalWinner.toString().replaceAll(","," ").replaceAll(".",",").replaceAll(" ", "."),
                winner: true,
                supplier: "Multi ganhador"
            }

            const partialQuotes = quotations.filter(e => parseFloat(e.winner_total.replaceAll(".","").replaceAll(",",".")) > 0).map(obj => ({ ...obj, winner: false }))
    
            setQuotesData([winnerQuote, ...partialQuotes])
        }
    }

    return (
        <div>
            {productOrderModal !== 0 &&
                <Modal 
                    open={productOrderModal >= 1 ? true : false}
                    primaryButtonText="Adicionar" 
                    secondaryButtonText="Cancelar" 
                    onRequestSubmit={onAddOrderProductAction} 
                    loadingDescription={"description"} 
                    onLoadingSuccess={() => console.log("sucess")}
                    onRequestClose={() => { setProductOrderModal(0); resetProductListForm() }}
                >
                    <Section level={4}>
                        <Stack gap={4}>
                            <Heading className="primary">Adicionar Itens ao Pedido</Heading>
                            {/* <Select
                                id="product_type"
                                name="product_type"
                                labelText="Tipo de Produto"
                                onChange={(e) => onFetchProductListByType(e.target.value)}   
                            >
                                <SelectItem value="" text="Escolha o tipo de Produto" />
                                {productCategories?.map((option, idx) => 
                                    <SelectItem key={idx} value={option.id} text={option.name} />
                                )}
                            </Select> */}
                            <TextInput 
                                type="text"
                                disabled={true}
                                value={selectedProduct ? selectedProduct?.name : ''}
                                id="product_name"
                                name="product_name"
                                labelText="Categoria do Pedido"
                            />
                            <TextInput 
                                type="text"
                                disabled={true}
                                value={selectedProduct  ? selectedProduct.presentation : ''}
                                id="product_presentation"
                                name="product_presentation"
                                labelText="Tipo"
                            />
                            <ComboBox
                                id="product_name"
                                name="product_name"
                                titleText="Buscar Produto"
                                placeholder="O que você procura?"
                                invalidText={productInvalidText}
                                invalid={isProductInvalid} 
                                onChange={e => onSetSelectedProduct(e.selectedItem)}
                                onInputChange={e => onSearchProduct(e)}
                                /* onToggleClick={e => console.log('hi')} */
                                items={productList ?? []}
                                itemToString={item => item ? `${item.name} ${item.presentation}` : ''} 
                            />
                            <TextInput 
                                type="text"
                                disabled={true}
                                value={selectedProduct  ? selectedProduct.presentation : ''}
                                id="product_presentation"
                                name="product_presentation"
                                labelText="Apresentação"
                            />
                            <TextInput 
                                type="text"
                                disabled={true}
                                value={selectedProduct  ? selectedProduct.description : ''}
                                id="product_desc"
                                name="product_desc"
                                labelText="Descrição"
                            />
                            <TextInput 
                                type="number"
                                value={productQuantity}
                                onChange={(e) => setProductQuantity(e.target.value)}
                                id="product_quantity"
                                name="product_quantity"
                                labelText="Quantidade Solicitada"
                                invalidText="A quantidade solicitada nao pode ser zero ou negativa."
                                invalid={isProductInvalidQuantity} 
                            />
                        </Stack> 
                    </Section>
                </Modal>
            }
            {/* { (quotesData && !orderItems) && quotesData?.map((e, idx) => {}) } */}
            { (quotesData && !orderItems) && quotesData?.filter(e => e.status === filter || e.winner ).map((e, idx) => 
                <div className="formBuilderDataGrid" name="Lista de Produtos">
                    <DataCRUD 
                        title={e.winner ? `Total Pedido: ${maskValue(e.winner_total, 'money')}` : `Total Cotado: ${maskValue(e.winner_total, 'money')}`} 
                        desc={`Fornecedor: ${e.supplier}`} 
                        headers={quoteItemsGridHeader} 
                        gridData={e}
                        flatGridData={true}
                        gridDataKey={'winner_quote_items'}
                        gridActions={gridOrderQuoteActions}
                        onGridAction={onAction}

                        gridAttachments={e?.attachments}

                        /* formTitleKey={'item_product_name'}
                        form={quoteForm} */
                        canAdd={false}
                        canChange={false}
                        canExport={false}
                        canBatchSelection={false}
                        searchBy={SEARCH_BY}
                        localGridData={true}
                        noScroll={true}
                    /> 
                </div>
            )}
            {orderItems &&
                <Section>
                    <div className="formBuilderDataGrid" name="Lista de Produtos">
                        <DataCRUD 
                            title={`Pedido ${managerData?.number ?? ''}`} 
                            desc={managerData?.winner ? `Fornecedor: ${managerData.winner.name} / Valor Total: ${maskValue(managerData.winner.total, 'money')}` : (editMode === OpMode.edit || editMode === OpMode.create) ? 'Adicione itens ao pedido clicando em “Adicionar”' : 'Todos os Itens'} 
                            headers={orderItemsGridHeader} 
                            gridData={orderItems}
                            onSelectData={(e) => console.log(e)}
                            canAdd={(canAddOrderItem && (editMode === OpMode.create || editMode === OpMode.edit))}
                            canDelete={editMode === OpMode.edit || editMode === OpMode.create}
                            canChange={canChangeProductList}
                            onDelete={onDeleteProductItem}
                            onAddRequest={onOrderItemAddRequest}
                            canExport={false}
                            canBatchSelection={editMode === OpMode.edit || editMode === OpMode.create}
                            searchBy={SEARCH_BY}
                            localGridData={true}
                            noScroll={true}
                        /> 
                    </div>
                </Section>
            }
        </div>
    )
}
