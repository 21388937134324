
// icons
import { Timer, CheckmarkFilled, CloseFilled, Money, SendAlt, ListNumbered, TaskRemove } from '@carbon/icons-react';

// api imports
import { getToQuoteOrdersApi, getQuoteApi, setQuoteApi } from '../utils/api/quotes'
import { getParentAndOwnEntitiesApi } from '../utils/api/entities'

// DataManager form setup for Orders
const form = [
    [
        {
            label: 'Código do Pedido',
            helper: '',
            type: 'text',
            key: 'number',
            disabled: true
        },
        {
            label: 'Solicitante',
            helper: '',
            type: 'text',
            key: 'requester_name',
            disabled: true
        },
        {
            label: 'Status',
            helper: '',
            type: 'text',
            key: 'status_code',
            disabled: true
        }
    ],
    [
        {
            label: 'Lançamento',
            helper: '',
            type: 'text',
            key: 'status_datetime',
            idx: 0,
            disabled: true
        },
        {
            label: 'Prazo para envio de cotações',
            helper: '',
            type: 'date',
            key: 'quote_deadline'
        },
        {
            label: 'Prazo de entrega',
            helper: '',
            type: 'date',
            key: 'shipping_deadline'
        }
    ],
    [
        {
            label: 'Valor Cotado',
            helper: '',
            type: 'money',
            key: 'my_quote_total',
            context: { status: [2] }
        },
        {
            label: 'Total da Compra',
            helper: '',
            type: 'money',
            key: 'my_quote_ordered',
            context: { status: [3,4,5] }
        },
        {},
        {}
    ],


    [
        {
            label: 'Dados de Envio / Remessa',
            helper: '',
            type: 'header',
            /* context: { status: [3,4,5] }, */
            disabled: true
        },
    ],
    [
        {
            label: 'CNPJ',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'entity_cnpj',
            disabled: true
        },
        {
            label: 'Nome',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'entity_name',
            disabled: true
        },
        {
            label: 'CEP',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'entity_cep',
            disabled: true
        }
    ],
    [
        {
            label: 'Endereço',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'entity_endereco',
            disabled: true
        },
        {
            label: 'Número',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'entity_numero',
            disabled: true
        },
        {
            label: 'Complemento',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'entity_complemento',
            disabled: true
        }
    ],
    [
        {
            label: 'UF',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'entity_uf_code',
            disabled: true
        },
        {
            label: 'Cidade',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'entity_cidade_code',
            disabled: true
        },
        {
            label: 'Gestor',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'entity_manager_name',
            disabled: true
        }
    ],
    /* [
        {
            label: 'Telefone',
            helper: '',
            type: 'text',
            key: 'entity_phone',
            disabled: true
        },
        {
            label: 'E-mail',
            helper: '',
            type: 'text',
            key: 'entity_email',
            disabled: true
        },
        {}
    ], */
    [
        {
            label: 'Dados da Nota Fiscal',
            helper: '',
            type: 'header',
            /* context: { status: [3,4,5] }, */
            disabled: true
        },
    ],
    [
        {
            label: 'CNPJ',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'parent_cnpj',
            disabled: true
        },
        {
            label: 'Nome',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'parent_name',
            disabled: true
        },
        {
            label: 'CEP',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'parent_cep',
            disabled: true
        }
    ],
    [
        {
            label: 'Endereço',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'parent_endereco',
            disabled: true
        },
        {
            label: 'Número',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'parent_numero',
            disabled: true
        },
        {
            label: 'Complemento',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'parent_complemento',
            disabled: true
        }
    ],
    [
        {
            label: 'UF',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'parent_uf_code',
            disabled: true
        },
        {
            label: 'Cidade',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'parent_cidade_code',
            disabled: true
        },
        {
            label: 'Gestor',
            helper: '',
            type: 'text',
            /* context: { status: [3,4,5] }, */
            key: 'parent_manager_name',
            disabled: true
        }
    ],
    /* [
        {
            label: 'Telefone',
            helper: '',
            type: 'text',
            key: 'parent_phone',
            disabled: true
        },
        {
            label: 'E-mail',
            helper: '',
            type: 'text',
            key: 'parent_email',
            disabled: true
        },
        {}
    ], */

    [
        {
            label: 'Notas do Solicitante',
            helper: '',
            type: 'header',
            disabled: true
        },
    ],
    [
        {
            label: 'Justificativa',
            helper: 'Limite de 500 caracteres',
            type: 'longText',
            key: 'justification'
        }
    ],
    [
        {
            label: 'Observações',
            helper: 'Limite de 500 caracteres',
            type: 'longText',
            key: 'observation'
        }
    ],
    [
        {
            label: 'Anexos',
            helper: '',
            type: 'multimedia',
            key: 'attachments',
            medias: [
                {
                    label: 'Documentos',
                    helper: 'Tamanho máximo de arquivo é 2Mb. Formatos suportados: Doc, PDF.',
                    key: 'doc'
                },
                {
                    label: 'Fotos e Imagens',
                    helper: 'Tamanho máximo de arquivo é 2Mb. Formatos suportados: JPG e PNG.',
                    key: 'image'
                },
                {
                    label: 'Videos',
                    helper: 'Suporte a links disponibilizados publicamente. Serviços suportados: Youtube, Vimeo, Dailymotion',
                    key: 'url'                    
                }
            ]
        }
    ],
    [
        {
            label: 'Processamento',
            helper: '',
            type: 'header',
            key: 'total',
            disabled: true
        },
    ],
    [
        {
            label: 'Envio do Pedido para Autorização em',
            helper: '',
            type: 'text',
            key: 'status_datetime',
            idx: 1,
            disabled: true
        },
        {
            label: 'Autorização para Cotação em',
            helper: '',
            type: 'text',
            key: 'status_datetime',
            idx: 2,
            disabled: true
        },
        {
            label: 'Ordem de Compra gerada em',
            helper: '',
            type: 'text',
            key: 'status_datetime',
            idx: 3,
            disabled: true
        },
    ],
    [
        {
            label: 'Envio do Pedido em',
            helper: '',
            type: 'text',
            key: 'status_datetime',
            idx: 4,
            disabled: true
        },
        {
            label: 'Recebimento do Pedido em',
            helper: '',
            type: 'text',
            key: 'status_datetime',
            idx: 5,
            disabled: true
        },
        {}
        /* {
            label: 'Recebido por',
            helper: '',
            type: 'text',
            key: 'status',
            disabled: true
        }, */
    ],
    [
        {
            label: 'Status for Actions',
            helper: '',
            type: 'hidden',
            key: 'status',
            disabled: true
        },
        {
            label: 'Order Items',
            helper: '',
            type: 'hidden',
            key: 'order_items'
        },
        {

            label: 'Quoted',
            helper: '',
            key: 'is_quoted_by_me',
            disabled: true
        },
        {

            label: 'Quotation',
            helper: '',
            key: 'my_quote',
            disabled: true
        },
        {

            label: 'Quotation Status',
            helper: '',
            key: 'my_quote_status',
            disabled: true
        },
        {
            label: 'Requester ID',
            helper: '',
            key: 'requester',
            disabled: true
        },
        {
            label: 'Entity Fetch',
            helper: '',
            key: '_',
            fetchApi: getParentAndOwnEntitiesApi,
            fetchApiParam: 'requester',
            disabled: true
        },
        {
            label: 'Entity Data',
            helper: '',
            key: 'entity',
            disabled: true
        },
        {
            label: 'Entity Parent Data',
            helper: '',
            key: 'parent',
            disabled: true
        }
    ]
]

// DataGrid headers and data table formating setup
const dataGridHeader = [
    {
        header: 'Código',
        key: 'number'
    },
    {
        header: 'Solicitante',
        key: 'requester_name'
    },
    {
        header: 'Responsável',
        key: 'responsible_code'
    },
    {
        header: 'Justificativa',
        key: 'justification'
    },
    {
        header: 'Lançamento',
        key: 'created_at'
    },
    {
        header: 'Itens',
        key: 'order_products'
    },
    {
        header: 'Prazo para envio da cotação',
        key: 'quote_deadline'
    },
    {
        header: 'Status',
        key: 'status_code'
    }
]

const dataGridSearchBy = ['number', 'requester', 'responsible', 'justification', 'created_at', 'order_products', 'quote_deadline'] //dataGridHeader.map(item => item.key.endsWith('_code') ? item.key.slice(0, -5) : item.key)
const dataGridFilterBy = [
    { 
        label: 'Aguardando Cotação',
        query: [
            {
                key: 'status',
                value: 1
            }
        ]
    },
    { 
        label: 'Cotados',
        query: [
            {
                key: 'status',
                value: 2
            }
        ]
    },
    { 
        label: 'Ordens de Compra',
        query: [
            {
                key: 'status',
                value: 3
            }
        ]
    },
    { 
        label: 'Remessas',
        query: [
            {
                key: 'status',
                value: 4
            }
        ]
    },
    { 
        label: 'Recebidos',
        query: [
            {
                key: 'status',
                value: 5
            }
        ]
    },
    { 
        label: 'Cancelados',
        query: [
            {
                key: 'status',
                value: 6
            }
        ]
    }
]

// grab those actions on onDataAction
const formActions = {
    "authorized": {
        "context": { "status": 1, "is_quoted_by_me": false },
        "label": "Preencher Cotação",
        "icon": Money
    },
    "quoted": {
        "context": { "status": [1,2], "is_quoted_by_me": true },
        "label": "Aguardando Avaliação",
        "icon": Timer,
        "disabled": true
    },
    "viewQuote": {
        "context": { "status": [1,2], "is_quoted_by_me": true },
        "label": "Visualizar Cotação",
        "icon": Money
    },
    "ordered": {
        "context": { "status": 3, "my_quote_status": 3 },
        "label": "Enviar Remessa",
        "icon": SendAlt
    },
    "refuseOrder": {
        "context": { "status": 3 },
        "label": "Desistir",
        "icon": TaskRemove
    },
    "viewItems": {
        "context": { "status": [3,4,5] },
        "label": "Visualizar Itens",
        "icon": ListNumbered
    },
    "canceled": {
        "context": { "status": 6 },
        "label": "Cancelado",
        "icon": CloseFilled,
        "disabled": true
    }
/*     "cancel": {
        "context": { "status": 1 }, 
        "label": "Cancelar Cotação",
        "icon": CloseFilled
    } */
}

// Datagrid for quote items
const quoteItemsGridHeader = [
    {
        header: 'Nome do Produto',
        key: 'item_product_name'
    },
    {
        header: 'Tipo',
        key: 'item_product_product_type_code'
    },
    {
        header: 'Solicitante',
        key: 'item_requester_name'
    },
    {
        header: 'Qtd Solicitada',
        key: 'item_quantity'
    },
    {
        header: 'Apresentação',
        key: 'item_product_presentation'
    },
    {
        header: 'Valor Unitário',
        key: 'price'
    },
    {
        header: 'Valor Total',
        key: 'total'
    }
]

const quoteItemsSearchBy = ['item_product_name', 'item_product_product_type_code', 'item_quantity', 'item_product_presentation', 'price', 'total']

// DataManager form setup
export const quoteForm = [
    [
        {
            label: 'Valor Unitário Proposto',
            helper: 'Itens cotados com valor R$ 0,00 (zero) serão ignorados.',
            type: 'money',
            key: 'price'
        },
        {
            label: 'Nome Comercial',
            helper: '',
            type: 'text',
            key: 'brand_name'
        },
        {
            label: 'EAN13',
            helper: '',
            type: 'text',
            key: 'ean13'
        }
    ],
    [
        {
            label: 'Fabricante',
            helper: '',
            type: 'text',
            key: 'manufacturer'
        },
        {
            label: 'Preço Fábrica (PF) Sem Impostos',
            helper: '',
            type: 'money',
            key: 'factory_price'
        },
        {
            label: 'Embalagem',
            helper: '',
            type: 'text',
            key: 'packaging',
            context: { item_product_product_type: 3 } // product type: 'Medicamento'
        }
    ],
    [
        {
            label: 'Registro',
            helper: '',
            type: 'text',
            key: 'registry',
            context: { item_product_product_type: 3 } // product type: 'Medicamento'
        },
        {
            label: 'Classe Terapêutica CMED',
            helper: '',
            type: 'text',
            key: 'therapeutic_class',
            context: { item_product_product_type: 3 } // product type: 'Medicamento'
        },
        {
            label: 'Forma Terapéutica',
            helper: '',
            type: 'text',
            key: 'therapeutic_form',
            context: { item_product_product_type: 3 } // product type: 'Medicamento'
        }
    ],
    [
        {
            label: 'Nome do Produto',
            helper: '',
            type: 'text',
            key: 'item_product_name',
            disabled: true
        },
        {
            label: 'Apresentação do Produto',
            helper: '',
            type: 'text',
            key: 'item_product_presentation',
            disabled: true
        },
        {
            label: 'Tipo',
            helper: '',
            type: 'text',
            key: 'item_product_product_type_code',
            disabled: true
        }
    ],
    [
        {
            label: 'Substância',
            helper: '',
            type: 'text',
            key: 'item_product_substance',
            disabled: true
        },
        {
            label: 'Concentração',
            helper: '',
            type: 'text',
            key: 'item_product_concentration',
            disabled: true
        },
        {
            label: 'CódigoBR',
            helper: '',
            type: 'text',
            key: 'item_product_code_br',
            disabled: true
        }
    ],
    [
        {
            label: 'Quantidade Solicitada',
            helper: '',
            type: 'text',
            key: 'item_quantity',
            disabled: true
        },
        {},
        {}
    ],
    [
        {
            label: 'Tipo',
            helper: '',
            type: 'hidden',
            key: 'item_product_product_type',
            disabled: true
        }
    ]
]

// grab those actions on onDataAction
const gridActions = {
    "toQuote": {
        "context": { "status": 0 },
        "label": "Consolidar e Enviar Cotação",
        "icon": SendAlt
    },
    "quoted": {
        "context": { "status": [1,2] },
        "label": "Aguardando Avaliação",
        "icon": Timer,
        "disabled": true
    },
    "ordered": {
        "context": { "status": 3 },
        "label": "Enviar Remessa",
        "icon": SendAlt
    },
    "received": {
        "context": { "status": 5 },
        "label": "Remessa Recebida",
        "icon": CheckmarkFilled,
        "disabled": true
    }
}

// re-process data from backend to distribute multi order orders into their own tabs
function handleQuoteData(data, filter) {
    if (!data || !Array.isArray(data) || data.length === 0) {
        return []
    }

    data.forEach(order => {
        if (order.status >= 1) {
            order.status = filter?.query?.[0]?.value
            order.status_code = filter.label
        }
        // if supplier had quoted, move to status 2
        /* if (order.is_quoted_by_me) {
            order.status = 2
            order.status_code = "Cotado"
        } */
    })
    
    return data
}

// the main DataCRUD setup
// the main grid list for suppliers to handle quotes
export const ordersDataCRUD = {
    headers: dataGridHeader, 
    searchBy: dataGridSearchBy,
    filters: dataGridFilterBy,
    form: form,
    formTitleKey: 'number',
    formActions: formActions,
    getApi: getToQuoteOrdersApi,
    getApiProcessData: handleQuoteData,
}

// the quote grid 
export const toQuoteDataCRUD = {
    headers: quoteItemsGridHeader, 
    searchBy: quoteItemsSearchBy,
    form: quoteForm,
    formTitleKey: 'item_product_name',
    formActions: formActions,
    getApi: getQuoteApi,
    setApi: setQuoteApi,
    gridActions: gridActions
}
